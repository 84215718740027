import React, { useState, useEffect } from "react";
import { Table, notification, } from "antd";
import axios from "axios";

const openNotificationWithIcon = (type, title, description) => {
  notification[type]({
    message: title,
    description: description,
    placement: "bottomLeft",
  });
};

const HoursWorked = () => {
  const [data, setData] = useState([]);
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    setLoading(true);
    axios
      .get("/backend/workorders/hours/") 
      .then((res) => {
        setData(res.data);
        setLoading(false);
      })
      .catch((err) => {
        console.error(err);
        openNotificationWithIcon("error", "Failed to fetch data", "Please try again later.");
        setLoading(false);
      });
  }, []);

  const columns = [
    {
      title: "Username",
      dataIndex: "username",
      key: "username",
      sorter: (a, b) => a.username.length - b.username.length,
    },
    {
      title: "This Months Hours",
      dataIndex: "current",
      key: "current",
      sorter: (a, b) => a.current - b.current,
      render: (text) => <span>{text}</span>,
    },
    {
      title: "Last Months Hours",
      dataIndex: "previous",
      key: "previous",
      sorter: (a, b) => a.previous - b.previous,
      render: (text) => <span>{text}</span>,
    },

  ];

  return (
    <div>
      <Table
        columns={columns}
        dataSource={data}
        rowKey="id"
        loading={loading}
        pagination={{ pageSize: 100 }}
      />
    </div>
  );
};

export default HoursWorked;
